<template>
  <div>
    <div class="crumbs">
      <a href="###">首页</a>
      <label>></label>
      <a href="###">借款申请</a>
      <label>></label>
      <a href="###">投标保证金贷款详情</a>
    </div>

    <div class="wrap-form-group">
      <el-form
        class="wrap-form"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="标地信息">
          <div class="input-row">
            {{ selectedBidProject.name }}
          </div>
        </el-form-item>
        <template v-if="this.form.bidProjectId">
          <el-form-item label="采购方">
            <span>{{ selectedBidProject.purchaseOrg }}</span>
          </el-form-item>
          <el-form-item label="项目名称">
            <span>{{ selectedBidProject.name }}</span>
          </el-form-item>
          <el-form-item label="招标机构">
            <span>{{ selectedBidProject.bidOrg }}</span>
          </el-form-item>
          <el-form-item label="代码">
            <span>{{ selectedBidProject.bidCode }}</span>
          </el-form-item>
        </template>

        <el-form-item label="投标保证金">
          <span>{{ form.margin }}元</span>
        </el-form-item>

        <el-form-item label="预期采购金额">
          <span>{{ form.purchaseMoney }}元</span>
        </el-form-item>

        <el-form-item label="借款金额">
          <span>{{ form.loanMoney }}元</span>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 69px">
        <el-button @click="cancel">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bidProjectList: [],
      form: {
        bidProjectId: "", // 标的信息id
        margin: "", // 投标保证金
        purchaseMoney: "", // 预期采购金额
        loanMoney: "", // 借款金额
      },
    };
  },
  computed: {
    selectedBidProject() {
      let list = this.bidProjectList.filter(
        (item) => item.id == this.form.bidProjectId
      );
      return list.length > 0 ? list[0] : {};
    },
  },
  mounted() {
    this.onInit();
    this.getBidProjectList();
  },
  watch: {
    "$route.params.id"() {
      this.onInit();
    },
  },
  methods: {
    onInit() {
      if (this.$route.params.id == 0) {
        this.form = {
          bidProjectId: "", // 标的信息id
          margin: "", // 投标保证金
          purchaseMoney: "", // 预期采购金额
          loanMoney: "", // 借款金额
        };
      } else {
        this.getDetail();
      }
    },
    getDetail() {
      this.$axios
        .get(`/marginloan/seachMarginLoanById/${this.$route.params.id}`)
        .then((res) => {
          if (res.state == 200) {
            this.form = {
              bidProjectId: res.data.bidProjectId,
              margin: res.data.margin,
              purchaseMoney: res.data.purchaseMoney,
              loanMoney: res.data.loanMoney,
              status: res.data.status,
            };
          } else {
            this.$message.error(res.message);
          }
        });
    },
    cancel() {
      this.$router.back();
    },

    getBidProjectList() {
      this.$axios.get("/bidProject/getBidProjectList").then((res) => {
        if (res.state == 200) {
          this.bidProjectList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-form-group {
  width: 941px;
  border: 1px solid #ededed;
  padding: 64px;
  box-sizing: border-box;
  .wrap-form {
    width: 350px;

    .input-row {
      display: flex;
    }
  }
}
</style>